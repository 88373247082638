.bg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: linear-gradient(to right, #1577b9, #da1b60);
}

.title {
  font-size: 5rem;
}

.img {
  pointer-events: fill;
  cursor: url('../assets/images/pointer-hover.png') 5 0, auto;
  transition: all 0.2s ease-in-out;
}

.img:hover {
  transform: scale(1.6);
}

@media (max-width: 500px) {
  .title {
    font-size: 3rem;
  }
}