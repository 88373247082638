.bg {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	background: linear-gradient(to right,#ff8a00,#da1b60);
	canvas {
		touch-action: none
	}
}
