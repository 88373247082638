@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;500;700&display=swap');

* {
  font-family: Signika, 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url('./assets/images/pointer.png') 5 0, auto;
  user-select: none;
}

#root {
  height: 100%;
}

a,
button {
  pointer-events: fill;
  cursor: url('./assets/images/pointer-hover.png') 5 0, auto;
  font-weight: 700;
}

p {
  font-weight: 300;
}

strong,
b {
  font-weight: 500;
}

h1,
h2,
h3 {
  font-weight: 700;
}
