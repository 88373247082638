.bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background: linear-gradient(to right, #f0d1f0, #ff94ba);
  font-size: 1.2rem;
  color: black;

  h2 {
    font-size: 3.6rem;
    padding-bottom: 20px;
  }
  h3 {
    font-size: 1.3rem;
  }
  .date {
    font-weight: italic;
    color: rgb(107, 9, 110);
  }
  p {
    padding-bottom: 20px;
  }
}

.time {
  padding-top: 220px;
  margin-bottom: 70px;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 1.2rem;
  color: black;
}

.title {
  opacity: 0.1;
  position: absolute;
  top: 5px;
  right: 15px;
  margin: 0;
  padding: 0;
  font-size: 35rem !important;
  text-transform: uppercase;
  background: url(./../assets/images/pattern.jpg);
  background-size: 800px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animate 30s linear infinite;
}


@keyframes animate {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 800px;
  }
  100% {
    background-position: 0 0;
  }
}

@media (max-width: 700px) {
  .time {
    padding-top: 350px;
  }
}

@media (max-width: 500px) {
  .time {
    padding-top: 350px;
  }
  .bg {
    .title {
      font-size: 25rem !important;
    }

    h2 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1rem;
    }
  }
}
