.header {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.div {
  width: 80px;
  height: 40px;
  transform: rotate(90deg);
}

.logo {
  width: 100%;
  height: 100%;
  fill: white;
  filter: drop-shadow(0.1rem 0.2rem rgb(138, 138, 138));
}

.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 15px;

    a {
      color: white;
    }

     a:after {
      content: '';
      display: block;
      margin: auto;
      height: 3px;
      width: 0;
      background: transparent;
      transition: width .5s ease, background-color .5s ease;
    }

    a:hover:after, .active:after {
      width: 90%;
      background: white;
    }

  }

  :last-child {
    margin-right: 0;
  }

  span {
    display: inline-block;
    margin-left: 10px;
    opacity: 0.5;
  }

}

@media (max-width: 500px) {
  .header {
    left: 20px;
  }
  .logodiv {
    display: none;
  }
}
