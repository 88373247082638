.div {
  position: absolute;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  pointer-events: fill;
  cursor: url('../../assets/images/cursor.png') 20 20, auto;
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 70vw;
  height: calc(70vw / 1.77);
  max-width: 880px;
  max-height: 495px;
  will-change: transform;
  border: 10px solid white;
  border-radius: 25px;
  box-shadow: 0 5px 50px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
}
