.btn {
  position: absolute;
  top: 0;
  right: 20px;
  background: #000;
  color: white;
  padding: 25px;
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
}

.btn {
  top: -80px;
}

.btn:hover {
  cursor: url('../../assets/images/pointer-hover.png') 5 0, auto;
  transform: scale(1.2);
}

