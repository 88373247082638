.bg {
  color: white;

  h1 {
    font-size: 9rem;
  }
}

@media (max-width: 500px) {
	.bg h1 {
		font-size: 5rem;
	}
}
