.bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: linear-gradient(to right, #7e257e, #da1b60);

  p {
    font-size: 1.2rem;
  }

  .link {
    pointer-events: fill;
    cursor: url('../assets/images/pointer-hover.png') 5 0, auto;
    &:hover {
      text-decoration: underline;
    }
  }
}
