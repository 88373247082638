.bg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: linear-gradient(to right, #1577b9, #da1b60);
  img {
    width: 75%;
  }
}

@media (max-width: 500px) {
  .bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    div:first-child {
      img {
        width: 75%;
      }
    }
  }
}
