* {
	box-sizing: border-box;
}

*::before {
	box-sizing: inherit;
}

*::after {
	box-sizing: inherit;
}

*:focus {
	outline: none;
}

html,
body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	overscroll-behavior-y: contain;
}

html {
	text-size-adjust: 100%;
	overflow-y: scroll;
}

@media only screen and (max-width: 480px) {
	html {
		font-size: 100%;
	}
}

body {
	background-color: #ffffff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: normal;
	word-wrap: break-word;
	font-kerning: normal;
	font-feature-settings: 'kern', 'liga', 'clig', 'calt';
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
progress,
video {
	display: inline-block;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

progress {
	vertical-align: baseline;
}

[hidden],
template {
	display: none;
}

a {
	background-color: transparent;
	text-decoration-skip: objects;
}

a,
a:visited,
a:hover {
	text-decoration: none;
}

a:active,
a:hover {
	outline-width: 0;
}

abbr {
	text-decoration: underline dotted;
	border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
	cursor: help;
}

b,
strong {
	font-weight: bolder;
}

dfn {
	font-style: italic;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	color: inherit;
	text-rendering: optimizeLegibility;
	margin: 0;
	padding: 0;
}

mark {
	border: 2px solid;
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

img {
	margin: 0;
	padding: 0;
	border-style: none;
}

svg:not(:root) {
	overflow: hidden;
}

code,
kbd,
pre,
samp {
	font-family: monospace;
	font-size: 1em;
}

figure {
	margin: 1em 40px;
}

hr {
	box-sizing: content-box;
	overflow: visible;
	margin: 0;
	padding: 0;
	border: none;
	height: 1px;
}

button,
input,
optgroup,
select,
textarea {
	margin: 0;
}

optgroup {
	font-weight: 700;
}

button,
input,
textarea {
	overflow: visible;
	background-color: transparent;
	border: none;
}

button,
select {
	text-transform: none;
}

button:hover {
	cursor: pointer;
}

[type='reset'],
[type='submit'],
button,
html [type='button'] {
	appearance: button;
}

[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
	outline: 1px dotted;
}

fieldset {
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	box-sizing: border-box;
	color: inherit;
	display: table;
	max-width: 100%;
	padding: 0;
	white-space: normal;
}

textarea {
	overflow: auto;
}

[type='checkbox'],
[type='radio'] {
	box-sizing: border-box;
	padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

[type='search'] {
	appearance: textfield;
	outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
	appearance: none;
}

input::placeholder {
	color: inherit;
	opacity: 0.54;
}

::-webkit-file-upload-button {
	appearance: button;
	font: inherit;
}

ul,
ol {
	margin: 0;
	padding: 0;
	list-style-position: outside;
	list-style-image: none;
}

ul li,
ol li {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

p,
dl,
dd,
hgroup,
figure,
fieldset,
form,
noscript,
iframe,
address {
	margin: 0;
	padding: 0;
}

pre {
	margin: 0;
	padding: 0;
	font-size: 0.8em;
	line-height: 1.42;
	background: hsla(0, 0%, 0%, 0.04);
	border-radius: 3px;
	overflow: auto;
	word-wrap: normal;
}

table {
	margin: 0;
	padding: 0;
	border-collapse: collapse;
	width: 100%;
}

blockquote {
	margin: 0 1.45rem;
	padding: 0;
}

b,
strong,
dt,
th {
	font-weight: bold;
}

li > ol {
	margin-left: 1.4rem;
	margin-bottom: calc(1.4rem / 2);
	margin-top: calc(1.4rem / 2);
}

li > ul {
	margin-left: 1.4rem;
	margin-bottom: calc(1.4rem / 2);
	margin-top: calc(1.4rem / 2);
}

blockquote *:last-child {
	margin-bottom: 0;
}

li *:last-child {
	margin-bottom: 0;
}

li > p {
	margin-bottom: calc(1.4rem / 2);
}

code {
	font-size: 0.85rem;
	line-height: 1.45rem;
}

kbd {
	font-size: 0.85rem;
	line-height: 1.45rem;
}

samp {
	font-size: 0.85rem;
	line-height: 1.45rem;
}

acronym {
	border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
	cursor: help;
}

thead {
	text-align: left;
}

td,
th {
	text-align: left;
	border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
	font-feature-settings: 'tnum';
	padding: 0.725rem 0.96667rem calc(0.725rem - 1px);
}

th:first-child,
td:first-child {
	padding-left: 0;
}

th:last-child,
td:last-child {
	padding-right: 0;
}

tt,
code {
	background-color: hsla(0, 0%, 0%, 0.04);
	border-radius: 3px;
	font-family: SFMono-Regular, Consolas, 'Roboto Mono', 'Droid Sans Mono', 'Liberation Mono', Menlo, Courier, monospace;
	padding: 0;
	padding-top: 0.2em;
	padding-bottom: 0.2em;
}

pre code {
	background: none;
	line-height: 1.42;
}

code::before,
code::after,
tt::before,
tt::after {
	letter-spacing: -0.2em;
	content: ' ';
}

pre code::before,
pre code::after,
pre tt::before,
pre tt::after {
	content: '';
}
